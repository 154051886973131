import BaseRoutes from './routes/router';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <main>
        <BaseRoutes />
      </main>
    </BrowserRouter>
  );
}

export default App;

import { Routes, Route } from 'react-router-dom';
import { Suspense } from 'react';
import LoginPage from '../pages/login/lodable';
import Loader from '../components/Loader/Loader';
import BaseLayout from '../Layouts/BaseLayout/lodable';

const BaseRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<LoginPage />}>
          <Route index={true} element={<LoginPage />}></Route>
        </Route>
        <Route path="/dashboard" element={<BaseLayout />}></Route>
      </Routes>
    </Suspense>
  );
};

export default BaseRoutes;
